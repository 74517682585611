// import React, { useState } from 'react';
// import axios from 'axios';
// import { v4 as uuidv4 } from 'uuid';
// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
// import Card from 'react-bootstrap/Card';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import { useNavigate } from 'react-router-dom';


// const RegistrationForm = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     gender: '',
//     institution: '',
//     telephone: '',
//     position: ''
//   });

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     // const user = { ...formData, id: uuidv4() };
//     const user = { ...formData};
//     try {
//       const res = await axios.post('http://120.26.81.229:5000/api/register', user);
//       console.log(res);
//       alert('报名成功!');
//       setFormData({
//         name: '',
//         gender: '',
//         institution: '',
//         telephone: '',
//         position: ''
//       });
//     } catch (error) {
//       console.error(error);
//       alert(error.response.data);
//     }
//   };
  
//   const navigate = useNavigate();
  
//   const handleAdminClick = () => {
//       navigate('/login');
//     };
  
//   return (
//     <>
//       <Navbar expand="lg" className="navbar navbar-expand-lg navbar-dark bg-dark">
//         <Navbar.Brand href="" className="ms-5">元岐医疗</Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="me-auto">
//               <NavDropdown title="Link" id="navbarScrollingDropdown">
//                 <NavDropdown.Item onClick={handleAdminClick}>Admin</NavDropdown.Item>
//                 <NavDropdown.Item href="#action4">
//                   Another action
//                 </NavDropdown.Item>
//                 <NavDropdown.Divider />
//                 <NavDropdown.Item href="#action5">
//                   Something else here
//                 </NavDropdown.Item>
//               </NavDropdown>

//           </Nav>
//         </Navbar.Collapse>
//       </Navbar>

//       <Container className="my-4">
//         <Card style={{ width: '50%', margin: '0 auto' }}>
//           <Card.Header className="bg-primary text-white">
//             <h3>报名表</h3>
//           </Card.Header>
//           <Card.Body>
//             <Form onSubmit={handleSubmit}>
//               <Form.Group className="mb-3">
//                 <Form.Label>姓名</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="name"
//                   value={formData.name}
//                   onChange={handleChange}
//                   placeholder="姓名"
//                   required
//                 />
//               </Form.Group>

//               <Form.Group className="mb-3">
//                 <Form.Label>性别</Form.Label>
//                 <Form.Control
//                   as="select"
//                   name="gender"
//                   value={formData.gender}
//                   onChange={handleChange}
//                   required
//                 >
//                   <option value="" disabled>性别</option>
//                   <option value="Male">男</option>
//                   <option value="Female">女</option>
//                   <option value="Other">其他</option>
//                 </Form.Control>
//               </Form.Group>

//               <Form.Group className="mb-3">
//                 <Form.Label>单位</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="institution"
//                   value={formData.institution}
//                   onChange={handleChange}
//                   placeholder="单位"
//                   required
//                 />
//               </Form.Group>

//               <Form.Group className="mb-3">
//                 <Form.Label>手机号码</Form.Label>
//                 <Form.Control
//                   type="tel"
//                   name="telephone"
//                   value={formData.telephone}
//                   onChange={handleChange}
//                   placeholder="手机号码"
//                   required
//                 />
//               </Form.Group>

//               <Form.Group className="mb-3">
//                 <Form.Label>岗位</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="position"
//                   value={formData.position}
//                   onChange={handleChange}
//                   placeholder="岗位"
//                   required
//                 />
//               </Form.Group>

//               <Button variant="primary" type="submit">报名</Button>
//             </Form>
//           </Card.Body>
//         </Card>
//       </Container>
//     </>
//   );
// };

// export default RegistrationForm;

import React, { useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from 'react-router-dom';

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    gender: '',
    institution: '',
    telephone: '',
    position: ''
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = { ...formData };
    try {
      const res = await axios.post('http://120.26.81.229:5000/api/register', user);
      console.log(res);
      alert('报名成功!');
      setFormData({
        name: '',
        gender: '',
        institution: '',
        telephone: '',
        position: ''
      });
    } catch (error) {
      console.error(error);
      alert(error.response?.data || '报名失败，请重试！');
    }
  };

  const handleAdminClick = () => {
    navigate('/login');
  };

  return (
    <>
      <style>{`
        /* 背景整体设置 */
        .registration-bg {
          min-height: 100vh;
          background: linear-gradient(135deg, #9FACE6 0%, #74EBD5 100%);
          display: flex;
          flex-direction: column;
        }
        /* 导航栏品牌名 */
        .navbar-brand {
          font-weight: bold;
          letter-spacing: 1px;
        }
        /* 卡片外观 */
        .register-card {
          background-color: rgba(255, 255, 255, 0.9);
          backdrop-filter: blur(5px);
          border: none;
          border-radius: 1.5rem; /* 加大圆角 */
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
          transition: transform 0.2s ease-in-out, box-shadow 0.2s;
        }
        /* 鼠标悬停时，让卡片略微浮起 */
        .register-card:hover {
          transform: translateY(-4px);
          box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
        }
        /* Card Header: 采用渐变背景，居中标题 */
        .card-header {
          border-top-left-radius: 1.5rem;
          border-top-right-radius: 1.5rem;
          background: linear-gradient(135deg, #2196F3, #21CBF3);
          color: #fff;
          text-align: center;
          font-size: 1.5rem;
          font-weight: 600;
        }
        /* 表单 Label 文本 */
        .form-label {
          margin-bottom: 0.5rem;
          font-weight: 500;
          color: #555;
        }
        /* 表单输入框的圆角和内边距 */
        .form-control {
          border-radius: 0.75rem;
          padding: 0.9rem;
          font-size: 0.95rem;
        }
        /* 提交按钮样式 */
        .submit-btn {
          width: 100%;
          font-weight: 500;
          letter-spacing: 1px;
          border-radius: 0.75rem;
          padding: 0.75rem;
          font-size: 1rem;
          background-color: #2196F3;
          border: none;
          transition: background-color 0.3s ease;
        }
        /* 提交按钮悬停效果 */
        .submit-btn:hover {
          background-color: #1976d2; 
        }
      `}</style>

      {/* 导航栏 */}
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="">元岐医疗</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <NavDropdown title="Link" id="navbarScrollingDropdown">
                <NavDropdown.Item onClick={handleAdminClick}>Admin</NavDropdown.Item>
                <NavDropdown.Item href="#action4">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action5">
                  Something else here
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* 主体内容 */}
      <div className="registration-bg d-flex align-items-center justify-content-center">
        <Container className="py-5">
          <Card className="register-card mx-auto" style={{ maxWidth: '600px' }}>
            <Card.Header>报名表</Card.Header>
            <Card.Body className="p-4">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label className="form-label">姓名</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="请输入您的姓名"
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="form-label">性别</Form.Label>
                  <Form.Select
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>选择性别</option>
                    <option value="Male">男</option>
                    <option value="Female">女</option>
                    <option value="Other">其他</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="form-label">单位</Form.Label>
                  <Form.Control
                    type="text"
                    name="institution"
                    value={formData.institution}
                    onChange={handleChange}
                    placeholder="请输入您所在单位"
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="form-label">手机号码</Form.Label>
                  <Form.Control
                    type="tel"
                    name="telephone"
                    value={formData.telephone}
                    onChange={handleChange}
                    placeholder="请输入您的手机号码"
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label className="form-label">岗位</Form.Label>
                  <Form.Control
                    type="text"
                    name="position"
                    value={formData.position}
                    onChange={handleChange}
                    placeholder="请输入您的岗位信息"
                    required
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="submit-btn">
                  提交报名
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default RegistrationForm;
